<template>
  <div id="redoc-container"></div>
</template>

<script>
import * as redoc from "redoc/bundles/redoc.standalone";
import { useRouter } from "@core/utils/utils";
import { onMounted } from "@vue/composition-api";

export default {
  data() {
    return {
      options_: {
        scrollYOffset: 50,
        hideDownloadButton: false,
      },
    };
  },
  watch: {
    $route(to, from) {
      // Refresh redoc if route change
      if (to !== from) {
        const jsonName = to.query.jsonName;
        redoc.init(
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          require(`@/openapi/${jsonName}`),
          {
            scrollYOffset: 50,
            hideDownloadButton: false,
            disableSearch: true,
          },
          document.getElementById("redoc-container")
        );
      }
    },
  },
  setup() {
    onMounted(() => {
      const { route } = useRouter();
      const jsonName = route.value.query.jsonName;
      redoc.init(
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        require(`@/openapi/${jsonName}`),
        {
          scrollYOffset: 50,
          hideDownloadButton: false,
          disableSearch: true,
        },
        document.getElementById("redoc-container")
      );
    });
  },
};
</script>
